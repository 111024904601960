import Vue from 'vue'
import * as VeeValidate from 'vee-validate';
import * as moment from 'moment';

import { ValidationProvider, ValidationObserver, extend} from 'vee-validate';
import { required, email, min, regex, alpha_spaces, alpha_num } from "vee-validate/dist/rules";
Vue.use(VeeValidate);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

extend("required", required);

extend("email", email);
extend("min", min);

extend("regx", regex);
extend("alpha_spaces", alpha_spaces);
extend("alpha_num", alpha_num);

const dateFormat = 'DD-MM-YYYY';

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Password confirmation does not match'
});


{/* 
  Example Uses: 
  ----------------------------
    <validation-provider name="Select Preferred Documents for Citizenship" 
      :rules="{ required: true, minArrayLength: 2 }" 
      v-slot="validationContext" >
      <Select2 class="small" v-model="passport_info.citizen_provement" 
        :options="citizen_provement_options" 
        :settings="{ multiple:true, maximumSelectionLength:2 }" />
      <span class="small text-danger">{{ validationContext.errors[0] }}</span>
    </validation-provider>
*/}
extend('minArrayLength', {
  validate: (value, data) => {
    if (value.length >= data) {
      return true;
    }
    return false;
  },
});



{/* 
  Example Uses: 
  ----------------------------
    <validation-provider name="Start Date" 
      :rules="{ required: true, before: endDate}" 
      v-slot="validationContext">
    </validation-provider> 
*/}
extend("before", {
  params: ["limit", "included"],
  validate: (value, { limit, included }) => {
    limit = moment(limit, dateFormat);
    value = moment(value, dateFormat);
    return included
      ? value.isSameOrBefore(limit)
      : value.isBefore(limit);
  },
  message: (fieldName, placeholders) => {
    let limit = moment(placeholders.limit).format(dateFormat);
    return `The ${fieldName} field must come before ${limit}`;
  }});


{/* 
  Example Uses: 
  ----------------------------
    <validation-provider name="End Date" 
      :rules="{ required: true, after: startDate}" 
      v-slot="validationContext">
    </validation-provider> 
*/}
extend("after", {
  params: ["limit", "included"],
  validate: (value, { limit, included }) => {
    limit = moment(limit, dateFormat);
    value = moment(value, dateFormat);
    return included
      ? value.isSameOrAfter(limit)
      : value.isAfter(limit);
  },
  message: (fieldName, placeholders) => {
    let limit = moment(placeholders.limit).format(dateFormat);
    return `The ${fieldName} cant be old`;
  }});
// https://stackoverflow.com/questions/57775092/how-can-i-validate-date-after-date-before-using-veevalidate-v3-and-vuejs-datep
