<template>
  <header class="site-navbar">

      <!-- bg-dark fixed-top navbar navbar-dark navbar-expand-lg -->
    <b-navbar id="mainNavigation" toggleable="lg" type="dark" variant="faded" fixed="top">
      <b-container>
        <b-navbar-brand to="/">
          <img src="~assets/images/stevesjobs-logo.png" alt="">
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" :isNav="true">
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto text-right">

            <b-nav-item to="/" exact exact-active-class="active">Home</b-nav-item>
            <client-only>
              <b-nav-item to="/my/profile" v-if="authenticated"><fa :icon="['fas', 'user']"></fa> Employer</b-nav-item>
            </client-only>
            <b-nav-item to="/login" v-if="!authenticated">Login</b-nav-item>
            <b-nav-item to="/register" v-if="!authenticated">Register</b-nav-item>

            <client-only>
              <b-nav-item-dropdown right v-if="authenticated" size="sm"
                :text="user.email" variant="outline-info">
                <b-dropdown-item to="/my/account-settings"><fa :icon="['fas', 'user-cog']"></fa> My Settings</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item href="javascript:" @click.prevent="logout()"><fa :icon="['fas', 'sign-out-alt']"></fa> Logout</b-dropdown-item>
              </b-nav-item-dropdown>
            </client-only>
            <b-nav-item to="/contact-us">Contact Us</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
    <div id="NavSpacer" v-bind:style="{height: menuHeight + 'px' }"></div>

  </header>
</template>

<script>
export default {
  data(){
    return {
      menuHeight: 70
    }
  },
  created(){
    if(process.client){
      //...your action here
      // console.log('this:: ', this)
      var height = document.getElementById('mainNavigation').clientHeight;
      // console.log('height:: ', height)
      this.menuHeight = height;
    }
  },
  methods: {
    logout() {
      let that = this
      this.$auth.logout()
    }
  }
}
</script>
