<template>
  <footer class="site-footer text-white-50">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center mb-2">
            <a class="mr-2" target="_blank" href="https://www.facebook.com/stevejobs.com.au/">
              <img class="footer-icon" src="~assets/images/fblogo.png" alt="Facebook page">
            </a> |
            <a class="ml-2" target="_blank" href="https://www.linkedin.com/company/stevejobsau">
              <img class="footer-icon" src="~assets/images/lnlogo.png" alt="LinkedIn profile">
            </a>
          </div>
          <div class="col-12 text-center copyright">
            Copyright © {{currentYear}} <a href="http://stevejobs.com.au">SteveJobs.com.au</a>
          </div>
        </div>
      </div>
    </footer>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    }
  },
  created() {
  },
}
</script>

<style lang="scss" scoped>

</style>
