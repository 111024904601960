export const state = () => ({
    employerData: new Object(null)
})

export const getters = {
  employerData(state) {
    return state.employerData
  }
}

export const mutations = {
  SET_EMPLOYER_DATA(state, employer) {
    state.employerData = employer;
  }
}

export const actions = {
  setEmployerData({ commit }, employer) {
    commit('SET_EMPLOYER_DATA', employer);
  },
  clearEmployerData({ commit }) {
    commit('SET_EMPLOYER_DATA', null);
  }
}
