import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5970c69d = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _de0b0f7a = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _28445b07 = () => interopDefault(import('../pages/Login.vue' /* webpackChunkName: "pages/Login" */))
const _035fb526 = () => interopDefault(import('../pages/nojob_index.vue' /* webpackChunkName: "pages/nojob_index" */))
const _6b8ab190 = () => interopDefault(import('../pages/Register/index.vue' /* webpackChunkName: "pages/Register/index" */))
const _100a2807 = () => interopDefault(import('../pages/my/account-settings/index.vue' /* webpackChunkName: "pages/my/account-settings/index" */))
const _3b08530a = () => interopDefault(import('../pages/my/profile/index.vue' /* webpackChunkName: "pages/my/profile/index" */))
const _777c1cb8 = () => interopDefault(import('../pages/job/_id/_slug/index.vue' /* webpackChunkName: "pages/job/_id/_slug/index" */))
const _47f5e54a = () => interopDefault(import('../pages/Register/_hash/_otp/index.vue' /* webpackChunkName: "pages/Register/_hash/_otp/index" */))
const _31aacfe0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contact-us",
    component: _5970c69d,
    name: "contact-us"
  }, {
    path: "/forgot-password",
    component: _de0b0f7a,
    name: "forgot-password"
  }, {
    path: "/Login",
    component: _28445b07,
    name: "Login"
  }, {
    path: "/nojob_index",
    component: _035fb526,
    name: "nojob_index"
  }, {
    path: "/Register",
    component: _6b8ab190,
    name: "Register"
  }, {
    path: "/my/account-settings",
    component: _100a2807,
    name: "my-account-settings"
  }, {
    path: "/my/profile",
    component: _3b08530a,
    name: "my-profile"
  }, {
    path: "/job/:id?/:slug",
    component: _777c1cb8,
    name: "job-id-slug"
  }, {
    path: "/Register/:hash/:otp",
    component: _47f5e54a,
    name: "Register-hash-otp"
  }, {
    path: "/",
    component: _31aacfe0,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
